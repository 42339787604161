<template>
  <div>
    <header class="presentation__objcart-footer onTop" ref="headerRef">
      <div class="wrapper">
        <span v-if="presData.title_description" class="wrapper-links" @click="backToPresentation">
          <q-icon size="20px" name="west" />
          <p class="link">
            {{
              headerRef && headerRef.offsetWidth > 500
                ? 'Подобранные варианты / '
                : 'Подобранные варианты'
            }}
          </p>
          <p class="linkp">
            {{
              presData?.title_description.type_description +
              `${
                presData?.title_description.sub_type_description
                  ? ', ' + presData?.title_description.sub_type_description
                  : ''
              }`
            }}
          </p>
        </span>
        <div class="wrapper-butts">
          <button @click="savePdf" class="rounded-butt">
            <base-icon name="download" size="20px" color="var(--violet-400)" />
            Скачать
          </button>
          <button class="rounded-butt" @click="copyToClipboard(true)">
            <base-icon name="copy" size="20px" color="var(--violet-400)" />
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :target="isCopiedTop"
              v-model="isCopiedTop"
              >Copied</q-tooltip
            >Ссылка
          </button>
        </div>
        <div class="wrapper-mobil-butts">
          <q-btn
            v-if="PopUp.$state.createPopUp"
            style="color: #aeacbf; width: 24px; min-height: 24px"
            icon="close"
            flat
            round
            dense
            @click="closeDialog"
          />
          <q-btn v-else style="color: #aeacbf; width: 24px; min-height: 24px" flat round dense>
            <base-icon size="24px" name="ellipsis-horizontal" color="#AEACBF" />
            <q-menu :offset="[90, 19]" auto-close class="info-menu-dropdown">
              <q-item clickable>
                <q-item-section>
                  <button @click="copyToClipboard(false)">
                    <base-icon name="copy" size="20px" color="var(--violet-400)" />
                    Скопировать ссылку
                  </button>
                </q-item-section>
              </q-item>
              <q-item clickable>
                <q-item-section>
                  <button>
                    <base-icon name="download" size="20px" color="var(--violet-400)" />
                    Сохранить в PDF
                  </button>
                </q-item-section>
              </q-item>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </header>
    <slot @obj_type="obj_type"></slot>
    <footer class="presentation__objcart-footer">
      <div class="wrapper">
        <span v-if="presData.title_description" class="wrapper-links" @click="backToPresentation">
          <q-icon size="20px" name="west" />
          <p class="link">
            {{ headerRef.offsetWidth > 500 ? 'Подобранные варианты / ' : 'Подобранные варианты' }}
          </p>
          <p class="linkp">
            {{
              presData?.title_description.type_description +
              `${
                presData?.title_description.sub_type_description
                  ? ', ' + presData?.title_description.sub_type_description
                  : ''
              }`
            }}
          </p>
        </span>
        <div class="wrapper-butts">
          <button>
            <base-icon @click="savePdf" name="download" size="20px" color="var(--violet-400)" />
            Скачать</button
          ><button @click="copyToClipboard(false)">
            <base-icon name="copy" size="20px" color="var(--violet-400)" />
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :target="isCopiedBot"
              v-model="isCopiedBot"
              >Copied</q-tooltip
            >Ссылка
          </button>
        </div>
      </div>
    </footer>
    <!-- <all-objs v-show="false" :triggerSave="handleSavePdf" /> -->
  </div>
  <!-- <pop-up /> -->
</template>

<script setup lang="ts">
import { debounce } from 'lodash'
import { PopUpStore } from '~/store/popUp'
import { PresentationStore } from '~/store/PresentationStore'
import 'animate.css'

const emit = defineEmits(['hide_popup'])

const router = useRouter()
const route = useRoute()
const PopUp = PopUpStore()
const PStore = PresentationStore()

const presData: any = computed(() => PStore.$state.standaloneData)

const handleSavePdf = ref(false)
const isCopiedTop = ref(false)
const isCopiedBot = ref(false)
const headerRef = ref()
const obj_type = ref('')

const closeDialog = () => {
  PopUp.$state.createPopUp = false
  emit('hide_popup', 'cc')
}

const savePdf = debounce(() => {
  PopUp.callPopUp(
    'PdfPopUp',
    {
      opts: {
        name: 'StandalonePdf',
        noPagesToPaginate: true,
      },
      ...PopUp.$state.currentPopUpData,
    },
    true,
  )
  handleSavePdf.value = true
  setTimeout(() => {
    handleSavePdf.value = false
  }, 500)
}, 500)

const copyToClipboard = debounce(async (c) => {
  try {
    await navigator.clipboard.writeText(window.location.href)
    c ? (isCopiedTop.value = true) : (isCopiedBot.value = true)
    setTimeout(() => {
      c ? (isCopiedTop.value = false) : (isCopiedBot.value = false)
    }, 500)
  } catch (error) {
    console.error('Failed to copy to clipboard', error)
  }
}, 200)

const backToPresentation = () => {
  router.push(`/presentation/${route.params.slug}`)
}
</script>

<style lang="scss">
@import '../assets/styles/_global';
body {
  background: #fff;
}
.info-menu {
  &-dropdown {
    border-radius: 12px;
    background: var(--White, #fff);
    box-shadow: 0px 2px 8px 0px rgba(29, 29, 31, 0.1);
    overflow: hidden;
    max-width: 300px !important;
    width: 215px !important;
    button {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--violet-400, #6b50b2);
      line-height: 22px;
      margin: 0;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
.presentation {
  &__objcart {
    &-footer {
      position: relative;
      bottom: 0;
      padding: 9px 0;
      border-top: 1px solid var(--slate-200, #e9e9f3);
      background: var(--White, #fff);
      width: 100%;
      display: flex;
      justify-content: center;
      .wrapper {
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        max-width: 1180px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        &-links {
          display: flex;
          color: #6b50b2;
          gap: 3px;
          align-items: center;
          cursor: pointer;
          .linkp {
            color: var(--slate-400, #aeacbf);
          }
        }
        p {
          line-height: 20px;
          margin: 0;
          cursor: pointer;
        }
        &-butts {
          display: flex;
          gap: 24px;
          button {
            border: none;
            background: transparent;
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--violet-400, #6b50b2);
            line-height: 22px;
            margin: 0;
            cursor: pointer;
          }
          .rounded-butt {
            border-radius: 90px;
            border: 1px solid var(--Slate-200, #e9e9f3);
            padding: 4px 11px;
            &:hover {
              background: var(--Slate-100, #f5f5fc);
            }
          }
        }
        &-mobil {
          &-butts {
            display: none;
          }
        }
      }
    }
  }
}
.onTop {
  position: relative;
  top: 0;
  bottom: unset;
}
@media screen and (min-width: 200px) and (max-width: 767px) {
  .presentation {
    padding-top: 43px;
    padding-bottom: 60px;
    &__objcart {
      &-footer {
        display: none;
        .wrapper {
          &-links {
            gap: 8px;
            .q-icon {
              font-size: 24px !important;
            }
            .link {
              display: none;
            }
            .linkp {
              color: var(--Slate-800, #2c2933);
              font-size: 16px;
              font-weight: 400;
            }
          }
          &-butts {
            display: none;
          }
          &-mobil {
            &-butts {
              display: block;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  .onTop {
    display: flex;
    padding-left: 16px;
    position: fixed;
    top: 0;
    z-index: 9999;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .presentation {
    &__objcart {
      &-footer {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
</style>
